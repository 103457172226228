<template>
  <div class="mission-container"
       :class="{ 'mission-past': past ,
                 'mission-current': todays,
                 'mission-future': future}" >
    <div v-if="notTodays" class="wrapper-today">
      <p class="day-text"> {{ missionData.day }}</p>
      <p class="subtitle-text"> {{ missionData.subtitle }}</p>
      <h1 class="title-text"> {{ missionData.title }}</h1>
      <div class="icon-container"
           :class="{
             'icon-past': past,
             'icon-future': future}">
        <img class="icon-lock" src="@/assets/img/icn_lock.png" width="64">
      </div>
      <p v-if="current !== 0" class="locked-text"> "Deze missie hebben jullie succesvol afgerond!" </p>
    </div>

    <div v-if="todays" class="wrapper-not-today">
      <p class="today-day-text"> {{ missionData.day }}</p>
      <div class="icon-container">
        <img class="icon-lock" src="@/assets/img/icn_lock.png" width="64">
      </div>
      <p class="subtitle-text-today"> {{ missionData.subtitle }}</p>
      <h1 class="title-text-today"> {{ missionData.title }}</h1>
      <BasicButton class="bottom-button" button-text="Start Missie" />
    </div>

  </div>
</template>

<script>

import BasicButton from '@/components/elements/basic/BasicButton'

export default {
  name: 'MissionLarge',
  components: {
    BasicButton
  },
  props: {

    missionData: {
      type: Object,
      required: true,
    },
    current: {
      //-1 = previous to today, 0 = current, 1 = locked,
      type: Number,
      required: true,
      default: 0
    }
  },
  // setup() {
  //
  // },
  computed: {
    todays: function() {
      return this.current === 0
    },
    notTodays: function() {
      return this.current !== 0
    },
    future: function() {
      return this.current === 1
    },
    past: function() {
      return this.current === -1
    }
  },
  // template: 'MissionLarge'
}

</script>

<style lang="scss" scoped>

@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.mission-container{

  padding: rem(22);
  min-width: rem(260);
  width: rem(260);
  color: white;
  height: rem(615);
  border-radius: rem(15);
}

.wrapper-today{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: left;
}

.wrapper-not-today{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.mission-past{
    background-color: #148F8A;
}

.mission-current{
    background: linear-gradient(180deg, #3240A2 0%, #1C287D 100%);
    height: rem(750);
}

.mission-future{
  background-color: #626DC6;
}

.today-day-text{
  color: white;
  padding: rem(8) rem(14);
  font-size: rem(16);
  background-color: #4C5FDA;
  border-radius: rem(20);
  width: fit-content;
  margin-top: rem(20);
  margin-bottom: rem(120);
}

.day-text{
  color: white;
  padding: rem(8) rem(14);
  font-size: rem(16);
  background-color: rgba($color: #FFFFFF, $alpha: 0.16);
  border-radius: rem(20);
  width: fit-content;
  // margin: rem(20) rem(20);
}

.subtitle-text{
  margin-top: rem(95);
  color: white;
}

.subtitle-text-today{
  margin-top: rem(40);
  font-size: rem(20);
  color: white;
}

.title-text-today{
  margin-top: rem(5);
  font-size: rem(45);
}

.title-text{
  margin-top: rem(5);
  font-size: rem(26);
}

.icon-container{
  display: flex;
  margin-top: rem(26);
  height: rem(240);
  // background-color: darkblue;
  border-radius: rem(25);
  align-items: center;
  justify-content: center;

}

.icon-future{
  background-color: #2D3A99;
}

.icon-past{
  background: linear-gradient(180deg, #139E9A 0%, #0DD2C6 100%);
}

.locked-text{
  margin-top: rem(30);
}

.bottom-button{
  margin-top: rem(65);
}

</style>
