<template>
  <div class="overview-mission-container">
    <div class="header-container">
      <router-link :to="{name:ROUTE_NAMES_TEACHER_PORTAL.OVERVIEW}" tag="div">
        <div class="back-button">
          <img src="@/assets/icons/icn_arrowback.svg" class="back-button-arrow">
          <p class="back-button-text">  {{ $t(OVERVIEW.RETURN_TO_OVERVIEW) }} </p>
        </div>
      </router-link>
      <img src="@/assets/icons/icn_logo_full.svg" class="media-icon" width="64">
      <img src="@/assets/icons/icn_logo_full.svg" class="week-icon" width="64">
    </div>
    <div class="missions-wrapper">
      <MissionLarge v-for="mission in missionsData"
                    :key="mission.id"
                    :mission-data="mission"
                    :current="mission.current"
                    :selected="currentMissionDay === mission.id"/>
    </div>
  </div>
</template>

<script>


import { ROUTE_NAMES_TEACHER_PORTAL } from '@/router/modules/teacherPortal'
import MissionLarge from '@/components/elements/missions/MissionLarge'

export default {
  name: 'OverviewMission',
  components: {
    MissionLarge,
  },
  setup() {
    const missionsData = []

    const currentMissionDay = 1
    const current = [-1, 0, 1, 1, 1]
    for (var i = 1; i < 6; i++) {
      missionsData.push(
        {
          id: i,
          current: current[i - 1],
          day: 'DAG ' + i,
          title: 'CRYPTO',
          subtitle: 'DE GEVAREN VAN',
          description: 'Beantwoord zo veel mogelijk vragen in de media quiz!',
          iconUrl: './../../assets/icons/icn_account.svg'
        }
      )
    }

    return {
      currentMissionDay,
      missionsData,
      ROUTE_NAMES_TEACHER_PORTAL
    }
  },
  template: 'OverviewMission'
}

</script>

<style lang="scss" scoped>

@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.overview-mission-container{
  overflow-y: auto;
  height: 100%;
  widows: 100vw;
  justify-content: center;
  background: linear-gradient(270deg, #4357E2 0%, #404FBD 100%);
  border-radius: 0px;
  z-index: 1;
}

.header-container{
  display: flex;
  justify-content: space-between;
  margin: rem(30) rem(40);
  align-items: center;
}

.back-button{
  color: white;
  display: flex;
  font-size: rem(18);
  gap: rem(15);
}

.back-button-arrow{
  width: rem(20);
  transform: rotate(180deg);
}

.media-icon{
  justify-self: center;
}

.week-icon{
}

.missions-wrapper{
  display: flex;
  justify-content: center;
  gap: rem(15);
  align-items: center;
  padding: rem(0) rem(100);
}

</style>
